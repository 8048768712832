import { CSpinner } from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { Input } from "../../components/inputs/Input";
import { apiConfig } from "../../config/apiConfig";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";

interface Props {
  target: "discount" | "bonus" | null;
  driverId: string;
  rides: string[];
  initialValue?: number;
  onClose: () => void;
}

const paymentOptions = [
  {
    label: "Espèces",
    value: "cash"
  },
  {
    label: "WafaCash",
    value: "wafacash"
  },

  {
    label: "Virement bancaire",
    value: "bank_transfer"
  }
];

const validations = yup.object({
  amount: yup
    .number()
    .required("Ce champ est obligatoire")
    .min(1, "Le solde doit être supérieur à 0"),
  paymentMethod: yup.string().required("Ce champ est obligatoire")
});

type InputDto = yup.InferType<typeof validations>;
type ApiDto = InputDto & {
  rides: string[];
};

export default function PayRideModalContent(props: Props) {
  const { target, onClose, driverId, rides } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset: resetForm
  } = useForm<InputDto>({
    resolver: yupResolver(validations),
    defaultValues: { amount: target === "bonus" ? 100 : props.initialValue }
  });

  const { toastError, toastSuccess } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();

  const endpoint = !target
    ? ""
    : target === "bonus"
    ? apiConfig.transactions.ride_bonus(driverId)
    : apiConfig.transactions.ride_discount(driverId);

  const giftDriver = useMutation({
    mutationFn: (data: ApiDto) => {
      return apiService.MakePostRequest(endpoint, data, token);
    },
    mutationKey: ["gift-driver", driverId, endpoint]
  });

  const onSubmit = async (data: InputDto) => {
    try {
      if (!rides || rides.length === 0) {
        return toastError("Veuillez sélectionner au moins un trajet");
      }
      await giftDriver.mutateAsync({ ...data, rides });
      toastSuccess(general.fr.message.rideBonusCreated);
      resetForm();
      queryClient.invalidateQueries();
      onClose();
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div className="border p-4 rounded-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="amount">Montant</label>
          <Input
            name="amount"
            type="number"
            className="custom-input"
            control={control}
          />
        </div>
        <div>
          <label htmlFor="paymentMethod">Mode de paiement</label>
          <Controller
            name="paymentMethod"
            control={control}
            defaultValue="cash"
            render={({ field }) => (
              <select {...field}>
                {paymentOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}
          />
          {errors?.paymentMethod && (
            <p className="text-red">{errors?.paymentMethod?.message}</p>
          )}
        </div>
        <div className="mt-4 d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-danger shadow-primary rounded-sm py-2 px-3 text-white"
            disabled={giftDriver.isLoading}
          >
            {giftDriver.isLoading ? (
              <div className="text-center">
                <CSpinner size="sm" />
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
