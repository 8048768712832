import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { toDateInputValue } from "../../../../helpers/general";
import { useCookie } from "../../../../hooks/useCookie";
import { useToast } from "../../../../hooks/useToast";
import { general } from "../../../../locales/general";
import { advancedCouponSchema } from "../../../../rules/coupon-validation";
import apiService from "../../../../service/apiService";
import CreateDiscountForm from "../form/CreateDiscountForm";

type FormSchemaType = Yup.InferType<typeof advancedCouponSchema>;

type DiscountValues = {
  usage: string;
  value: number;
};
export default function UpdateDiscount() {
  const [isAdvanced, setIsAdvanced] = useState(false);
  const form = useForm<FormSchemaType>({
    resolver: yupResolver(advancedCouponSchema)
  });

  const { token } = useCookie("vToken");

  const { id } = useParams();
  const { data } = useQuery<CouponResponse>({
    queryKey: ["taxi-coupons", id],
    queryFn: () => apiService.MakeGetRequest(`taxi-coupons/${id}`, token)
  });

  useEffect(() => {
    if (data) {
      const discounts = Object.entries(data.discount).map(
        ([regKey, usages]) => ({
          registraction: regKey, 
          value: Object.entries(usages).map(([usageKey, value]) => ({
            usage: usageKey,
            value
          }))
        })
      );

      const defaultValues = [
        { key: "id", value: data.id },
        { key: "name", value: data.name },
        { key: "discount", value: discounts },
        { key: "maxUsage", value: data.maxUsage ?? 0 },
        { key: "maxPerUser", value: data.maxPerUser ?? 0 },
        { key: "isActive", value: Boolean(data.isActive) },
        { key: "isPublic", value: Boolean(data.isPublic) },
        {
          key: "experationDate",
          value: data.experationDate
            ? toDateInputValue(data.experationDate)
            : ""
        },
        { key: "comments", value: data.comments ?? "" }
      ];
      defaultValues.forEach(value => {
        // @ts-ignore
        form.setValue(value.key, value.value, {
          shouldDirty: true,
          shouldValidate: true
        });
      });
      const hasAdvancedDiscounts =
        discounts.length > 1 ||
        discounts.some(discount => discount.value.length > 1);

      console.log("Has Advanced Discounts:", hasAdvancedDiscounts);
      setIsAdvanced(hasAdvancedDiscounts);
    }
  }, [data, form, setIsAdvanced]);

  const updateDiscountMutation = useMutation({
    mutationFn: (payload: FormSchemaType) =>
      apiService.MakePutRequest(`taxi-coupons/${data?.id}`, payload, token),
    mutationKey: ["taxi-coupons", data?.id]
  });

  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();

  const onSubmit = (data: FormSchemaType) => {
    updateDiscountMutation.mutate(data, {
      onSuccess: () => {
        toastSuccess("Coupon mis à jour avec succès");
        navigate("/taxi/discounts");
        form.reset();
      },
      onError: error => {
        toastError(general.fr.message.operationFailed);
      }
    });
  };
  console.dir(data, { depth: null });
  // console.log(data?.isPublic)

  return (
    <div className="bg-white p-3 rounded-md">
      <h1 className="text-lg fw-bold mb-4">Mettre à jour le coupon</h1>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CreateDiscountForm
          control={form.control}
          isActive={data?.isActive}
          isPublic={data?.isPublic}
          isAdvanced={isAdvanced}
          setIsAdvanced={setIsAdvanced}
          mode='update'
        />
        <button
          className="btn btn-danger my-3 me-2"
          type="button"
          onClick={() => navigate("/taxi/discounts")}
          disabled={updateDiscountMutation.isLoading}
        >
          Annuler
        </button>
        <button
          className="btn btn-primary my-3 text-white"
          type="submit"
          disabled={updateDiscountMutation.isLoading}
        >
          {updateDiscountMutation.isLoading
            ? "Mise à jour en cours..."
            : "Mettre à jour le Coupon"}
        </button>
      </form>
    </div>
  );
}
