import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { DELETE_ACTIONS } from "../../config/constants";
import { colors } from "../../config/theme";
import { getBadgeClassNameByStatus } from "../../helpers/colors";
import {
  TransactionStatus,
  TransactionStatusLabel,
  TransactionType,
  TransactionTypeLabel
} from "../../helpers/enums";
import { formatDateString } from "../../helpers/general";

interface TransactionsTableProps {
  transactions: Transaction[];
  onTransactionSelect: React.Dispatch<BulkDeleteActions>;
  showuser?: boolean;
}

export default function TransactionsTable(props: TransactionsTableProps) {
  const { transactions, onTransactionSelect, showuser = true } = props;

  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            {showuser && (
              <CTableHeaderCell scope="col" className="text-sm">
                Utilisateur
              </CTableHeaderCell>
            )}
            <CTableHeaderCell scope="col" className="text-sm">
              Date
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Montant
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Type
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Statut
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Méthode de paiement
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Id de transaction
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              Wafacash code
            </CTableHeaderCell>

            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {transactions?.map(transaction => (
            <TableBodyRowContent
              transaction={transaction}
              key={transaction?.id}
              deletedList={onTransactionSelect}
              showUser={showuser}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}

function TableBodyRowContent({
  transaction,
  deletedList,
  showUser
}: {
  transaction: Transaction;
  deletedList: React.Dispatch<BulkDeleteActions>;
  showUser: boolean;
}) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: transaction.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: transaction.id });
    }
  }, [deletedList, isChecked, transaction.id]);

  // const removeTransaction = () => {};

  const type = transaction.type as Lowercase<keyof typeof TransactionType>;
  const status = transaction.status as Lowercase<
    keyof typeof TransactionStatus
  >;

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%"
      }}
    >
      {showUser && (
        <CTableDataCell className="px-3">
          <div className="d-flex align-items-center gap-3">
            {/* <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          /> */}
            <img
              src={
                transaction?.user?.profilePicture ??
                `https://ui-avatars.com/api/?name=${transaction?.user?.fullName}`
              }
              alt=""
              width={30}
              height={30}
              style={{ borderRadius: "100%" }}
            />

            <p className="m-0 text-sm">{transaction?.user?.fullName}</p>
          </div>
        </CTableDataCell>
      )}
      <CTableDataCell className="p-3 text-sm">
        {formatDateString(transaction?.createdAt)}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {transaction.amount}
      </CTableDataCell>
      <CTableDataCell className="p-3  text-sm fw-semibold">
        {TransactionTypeLabel[type]}
      </CTableDataCell>
      <CTableDataCell className="p-3  text-sm fw-semibold">
        <button className={getBadgeClassNameByStatus(status)}>
          {TransactionStatusLabel[status]}
        </button>
      </CTableDataCell>
      <CTableDataCell className="p-3  text-sm">
        {transaction.paymentMethod}
      </CTableDataCell>
      <CTableDataCell className="p-3  text-sm">
        {transaction.transactionOid}
      </CTableDataCell>
      <CTableDataCell className="p-3  text-sm">
        {transaction.wafacashCode}
      </CTableDataCell>
    </CTableRow>
  );
}
