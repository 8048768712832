import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { apiConfig } from "../config/apiConfig";
import { RideStatus } from "../helpers/enums";
import apiService from "../service/apiService";
import { useCookie } from "./useCookie";

type Query = {
  query: string;
  page: number;
  status?: RideStatus;
  paymentMethod?: "cash";
  startDate?: string;
  endDate?: string;
  isValidRide?: string;
  payed?: string;
  driverId?: string;
};

type RideResponse = {
  items: Ride[];
  meta: PaginationMeta;
};

type UnpaidRidesResponse = {
  currentCount: number;
  requiredAmount: number;
  bonus: number;
};

const defaultQuery = {
  query: "",
  page: 1,
  status: undefined,
  paymentMethod: undefined,
  startDate: undefined,
  endDate: undefined,
  driverId: undefined,
  payed: undefined,
  isValidRide: undefined
};

export function useRides(defaultQueries?: Partial<Query>) {
  const [queries, setQueries] = useState<Query>(defaultQuery);

  useEffect(() => {
    if (defaultQueries) {
      setQueries({ ...defaultQuery, ...defaultQueries });
    }
  }, [defaultQueries]);

  const endpoint = apiConfig.rides.all(queries);
  const { token } = useCookie("vToken");

  const {
    query,
    page,
    status,
    paymentMethod,
    startDate,
    endDate,
    isValidRide,
    payed,
    driverId
  } = queries;
  const deps = [
    "rides",
    query,
    page,
    status,
    paymentMethod,
    startDate,
    endDate,
    endpoint,
    isValidRide,
    payed,
    driverId
  ];

  const { data, isLoading } = useQuery<RideResponse>(deps, () => {
    return apiService.MakeGetRequest(endpoint, token);
  });

  const { data: unpaidRides, isLoading: isLoadingUnpaid } = useQuery<
    UnpaidRidesResponse
  >(
    ["unpaidRides", driverId],
    () => {
      if (!driverId)
        return Promise.resolve({
          currentCount: 0,
          requiredAmount: 0,
          bonus: 0
        });
      return apiService.MakeGetRequest(
        apiConfig.users.unpaidBonus(driverId),
        token
      );
    },
    { enabled: !!driverId }
  );

  const addQuery = <K extends keyof typeof queries>(
    key: K,
    value: typeof queries[K]
  ) => {
    const page = key === "page" ? (value as number) : 1;
    setQueries(prev => ({ ...prev, [key]: value, page }));
  };
  const setDates = (dates: Dates) => {
    setQueries(prev => ({
      ...prev,
      startDate: dates.from,
      endDate: dates.to,
      page: 1
    }));
  };

  const removeRide = async (rideId: String) => {};

  return {
    rides: data,
    isLoading,
    queries,
    addQuery,
    removeRide,
    setDates,
    unpaidRides,
    isLoadingUnpaid
  };
}
