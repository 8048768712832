import { CFormTextarea } from "@coreui/react";
import { Controller, useFieldArray } from "react-hook-form";
import { Input } from "../../../../components/inputs/Input";

interface CreateDiscountFormProps {
  control: any;
  isActive?: boolean;
  isPublic?: boolean;
  isAdvanced?: boolean;
  setIsAdvanced?: (value: boolean) => void;
  mode?: "create" | "update";
}

export default function CreateDiscountForm({
  control,
  isAdvanced = false,
  setIsAdvanced,
  mode = "create"
}: CreateDiscountFormProps) {
  return (
    <section>
      <div className="create-discount-form">
        {/* Basic Fields */}
        <Input
          name="name"
          control={control}
          type="text"
          label="Nom"
          placeholder="Nom"
        />
        <Input
          name="experationDate"
          control={control}
          type="date"
          label="Date d'expiration"
          placeholder="Date d'expiration"
        />
        <Input
          name="maxUsage"
          control={control}
          type="number"
          min={1}
          label="Utilisation maximale"
        />
        <Input
          name="maxPerUser"
          control={control}
          type="number"
          min={1}
          label="Utilisation par utilisateur"
        />
        {/* Checkboxes */}
        <Controller
          name="isActive"
          control={control}
          render={({ field }) => (
            <div className="d-flex align-items-center gap-1 mt-2">
              <input
                id="isActive"
                type="checkbox"
                {...field}
                //defaultChecked={isActive}
                checked={field.value}
              />
              <label htmlFor="isActive">Actif</label>
            </div>
          )}
        />
        <Controller
          name="isPublic"
          control={control}
          render={({ field }) => (
            <div className="d-flex align-items-center gap-1 mt-2">
              <input
                id="isPublic"
                type="checkbox"
                {...field}
                checked={field.value}
              />
              <label htmlFor="isPublic">Public</label>
            </div>
          )}
        />
        {isAdvanced && <AdvancedDiscountSection control={control} />}
        {!isAdvanced && (
          <>
            <div className="p-3 rounded-md border">
              <p className="fw-bold mt-3">Remise</p>
              <Input
                name="discount.0.registraction"
                control={control}
                type="hidden"
                defaultValue={1}
              />

              <Input
                name="discount.0.value.0.usage"
                control={control}
                type="hidden"
                defaultValue={1}
              />
              <Input
                name="discount.0.value.0.value"
                control={control}
                type="number"
                label="Valeur de la remise (%)"
                min={0}
              />
            </div>
          </>
        )}
        <div className="w-fit"></div>

        {mode !== "update" ? (
          <button
            type="button"
            className="btn btn-secondary text-white mt-3 w-25"
            onClick={() => setIsAdvanced && setIsAdvanced(!isAdvanced)}
          >
            {isAdvanced ? "Coupon Simple" : "Coupon avancé"}
          </button>
        ) : mode === "update" && !isAdvanced ? (
          <button
            type="button"
            className="btn btn-secondary text-white mt-3 w-25"
            onClick={() => setIsAdvanced && setIsAdvanced(!isAdvanced)}
          >
            {isAdvanced ? "Coupon Simple" : "Coupon avancé"}
          </button>
        ) : (
          ""
        )}
      </div>

      <Controller
        name="comments"
        control={control}
        render={({ field }) => (
          <div className="mt-3">
            <label htmlFor="comments">Commentaires</label>
            <CFormTextarea
              id="comments"
              {...field}
              placeholder="Commentaires"
            />
          </div>
        )}
      />
    </section>
  );
}

interface AdvancedDiscountSectionProps {
  control: any;
}

const AdvancedDiscountSection = ({ control }: AdvancedDiscountSectionProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "discount"
  });

  return (
    <div className="p-3 rounded-md border mt-3">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p className="fw-bold">Remises Avancées</p>
      </div>

      {fields.length === 0 && <p>Aucune remise supplémentaire</p>}

      {fields.map((field, index) => (
        <DiscountItem
          key={field.id}
          control={control}
          index={index}
          onRemove={() => remove(index)}
        />
      ))}

      <button
        type="button"
        className="btn btn-primary mt-2"
        onClick={() => append({})}
      >
        Ajouter une remise
      </button>
    </div>
  );
};

interface DiscountItemProps {
  control: any;
  index: number;
  onRemove: () => void;
}

const DiscountItem = ({ control, index, onRemove }: DiscountItemProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `discount.${index}.value`
  });

  return (
    <div className="mb-4 border-bottom pb-2">
      {/* Editable Registration Input */}
      <Input
        name={`discount.${index}.registraction`}
        control={control}
        type="number"
        min={0}
        max={100}
        label="Registration"
      />

      <p className="fw-bold mt-2">Coupon usage</p>

      {fields.length === 0 && <p>Aucune usage</p>}

      {fields.map((field, idx) => (
        <div key={field.id} className="d-flex align-item-center mt-2 gap-2">
          <Input
            name={`discount.${index}.value.${idx}.usage`}
            control={control}
            type="number"
            label="Intervalle d'utilisation"
          />
          <Input
            name={`discount.${index}.value.${idx}.value`}
            control={control}
            type="number"
            label="Valeur de la remise (%)"
            min={0}
          />
          <button
            type="button"
            className="btn text-danger"
            onClick={() => remove(idx)}
          >
            Supprimer
          </button>
        </div>
      ))}

      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-primary mt-2"
          onClick={() => append({})}
        >
          Ajouter une usage
        </button>
        <button
          type="button"
          className="btn btn-danger mt-2"
          onClick={onRemove}
        >
          Supprimer la remise
        </button>
      </div>
    </div>
  );
};
