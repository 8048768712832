import {
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import { faPercent } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import PromptModal from "../../components/modals/PromptModal";
import { DELETE_ACTIONS } from "../../config/constants";
import { colors } from "../../config/theme";
import { getBadgeClassNameByStatus } from "../../helpers/colors";
import { RideStatus, RideStatusLabels } from "../../helpers/enums";
import { formatDateString } from "../../helpers/general";
import { useRides } from "../../hooks/useRides";

interface Props {
  riders: Ride[];
  onRiderSelect: React.Dispatch<BulkDeleteActions>;
  showDriver?: boolean;
  selectedRides: string[];
}

interface TableBodyProps {
  ride: Ride;
  showDriver?: boolean;
  deletedList: React.Dispatch<BulkDeleteActions>;
  selectedRides: string[];
}

const ONE_KILOMETER = 1000;
function metersToKilometers(meters: number): number {
  return meters < ONE_KILOMETER ? meters : meters / ONE_KILOMETER;
}

function TableBodyRowContent({
  ride,
  deletedList,
  showDriver,
  selectedRides
}: TableBodyProps) {
  const [showModal, setShowModal] = useState(false);
  const { removeRide } = useRides();

  const isChecked = selectedRides.includes(ride.id);

  const toggleCheckBox = useCallback(
    (state: boolean) => {
      if (state) {
        deletedList({ type: DELETE_ACTIONS.ADD, id: ride.id });
      } else {
        deletedList({ type: DELETE_ACTIONS.REMOVE, id: ride.id });
      }
    },
    [ride.id]
  );

  const discountAmount = ride?.priceCalculation?.coupon?.isCouponApplied
    ? +ride?.originalFare - ride?.priceCalculation?.coupon?.fareAfterCoupon
    : 0;

  const disableCheckbox =
    !ride.metaData?.validRide ||
    (ride?.metaData?.driverPayment?.paid &&
      (ride?.priceCalculation?.coupon?.isCouponApplied
        ? ride?.metaData?.couponDiscount?.paid
        : true));

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell>
        {!showDriver && (
          <CFormCheck
            label=""
            defaultChecked={isChecked}
            onChange={e => toggleCheckBox(e.target.checked)}
            color={colors.yellow}
            disabled={disableCheckbox || ride.status !== RideStatus.COMPLETED}
          />
        )}
        <Link
          to={`/rides/${ride?.id}/ridedetails`}
          state={{ ride: ride }}
          className="p-2 text-sm"
        >
          {formatDateString(ride.createdAt)} -{" "}
          {format(new Date(ride.createdAt), "HH:mm")}
        </Link>
        {ride?.priceCalculation?.coupon?.isCouponApplied && (
          <div
            className=" d-flex align-items-center justify-content-center "
            style={{
              top: "5px",
              left: "10px",
              background: "linear-gradient(45deg, #FF7722, #FFB800)",
              padding: "2px 4px",
              margin: "0px 25px",
              borderRadius: "30px",
              fontSize: "1rem",
              color: "white"
            }}
          >
            -{ride?.priceCalculation?.coupon?.couponPercentage}{" "}
            <FontAwesomeIcon icon={faPercent} className="me-2" />
          </div>
        )}
      </CTableDataCell>

      {showDriver && (
        <CTableDataCell className="px-3 text-sm">
          <div className="d-flex align-items-center gap-3">
            {/* <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          /> */}
            {ride?.driver && (
              <>
                <img
                  src={
                    ride?.driver?.profilePicture ??
                    `https://ui-avatars.com/api/?name=${ride?.driver?.fullName}`
                  }
                  alt=""
                  width={30}
                  height={30}
                  style={{ borderRadius: "100%" }}
                />

                <p className="m-0">{ride?.driver?.fullName}</p>
              </>
            )}
          </div>
        </CTableDataCell>
      )}
      <CTableDataCell className="px-3 text-sm">
        {ride?.user && (
          <div className="d-flex align-items-center gap-3">
            <img
              src={
                ride?.user?.profilePicture ??
                `https://ui-avatars.com/api/?name=${ride?.user?.fullName}`
              }
              alt=""
              width={30}
              height={30}
              style={{ borderRadius: "100%" }}
            />

            <div>
              <p className="m-0">{ride.user?.fullName}</p>
            </div>
          </div>
        )}
      </CTableDataCell>

      <CTableDataCell
        className=" p-3  text-sm d-flex flex-column "
        style={{
          // maxWidth: "200px",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          whiteSpace: "wrap"
        }}
      >
        <span>
          <span className="fw-bold">De: </span>
          {ride.pickupLocationName}
        </span>

        <span>
          <span className="fw-bold">À: </span>
          {ride.destinationLocationName}
        </span>
      </CTableDataCell>

      {/* <CTableDataCell
        className="p-3 w-75 text-sm"
        style={{
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "wrap"
        }}
      >
        {ride.destinationLocationName}
      </CTableDataCell> */}

      <CTableDataCell className={`p-3 text-sm`}>
        <span className={getBadgeClassNameByStatus(ride.status)}>
          {RideStatusLabels[ride.status as keyof typeof RideStatusLabels]}
        </span>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {ride.paymentMethod}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {metersToKilometers(ride.distance).toFixed(2)}{" "}
        {ride.distance < ONE_KILOMETER ? "m" : "Km"}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {ride.estimatedDuration ? Math.floor(ride.estimatedDuration / 60) : ""}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {ride.originalFare}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">{discountAmount}</CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {ride?.priceCalculation?.coupon?.fareAfterCoupon}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {ride?.metaData?.driverPayment?.paid ? "✅" : "❌"}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {ride?.priceCalculation?.coupon?.isCouponApplied
          ? ride?.metaData?.couponDiscount?.paid
            ? "✅"
            : "❌"
          : "__"}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {isEmpty(ride.metaData) ? "__" : ride.metaData?.validRide ? "✅" : "❌"}
      </CTableDataCell>
      {/* <CTableDataCell className="p-3 text-sm  align-items-center justify-content-center">
        <Link to={`/rides/${ride?.id}/conversations`} className="text-black">
          <ChatBubbleLeftRightIcon width="25" height="25" />
        </Link>
      </CTableDataCell> */}
      <PromptModal
        open={showModal}
        onAccept={() => removeRide(ride?.id)}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

export default function RidersTable(props: Props) {
  const { riders, onRiderSelect, showDriver = true, selectedRides } = props;
  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Date du trajet
              </div>
            </CTableHeaderCell>
            {showDriver && (
              <CTableHeaderCell scope="col" className="text-sm">
                <div className="d-flex align-items-center justify-content-between">
                  Chauffeur
                </div>
              </CTableHeaderCell>
            )}
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Client
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Localisation et Destination
              </div>
            </CTableHeaderCell>
            {/* <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Destination
              </div>
            </CTableHeaderCell> */}
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Statut
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm ">
              <div className="d-flex align-items-center justify-content-between">
                Moyen de paiement
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Distance
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Durée estimée (min)
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Frais originaux (MAD)
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Montant du coupon (MAD)
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Montant payé (MAD)
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Bonus Payé ?
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Coupon Payé ?
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Le trajet est-il valid ?
              </div>
            </CTableHeaderCell>

            {/* <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell> */}
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {riders?.map(ride => (
            <TableBodyRowContent
              key={ride.id}
              ride={ride}
              deletedList={onRiderSelect}
              showDriver={showDriver}
              selectedRides={selectedRides}
            />
          ))}
        </CTableBody>
      </CTable>
    </div>
  );
}
