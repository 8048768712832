import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useReducer, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import BoxWrapper from "../../../components/BoxWrapper";
import Pagination from "../../../components/Pagination";
import RenderTable from "../../../components/RenderTable";
import { OffersSvg } from "../../../components/SideBarMenu";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../../context/bulkDeleteReducer";
import { pluralize, toQuery } from "../../../helpers/general";
import { useCookie } from "../../../hooks/useCookie";
import { useDebounce } from "../../../hooks/useDebounce";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import apiService from "../../../service/apiService";
import AdminRoute from "../../auth/RestrictedRoute";
import TaxiCouponsTable from "./TaxiCouponsTable";

interface Coupons {
  meta: PaginationMeta;
  items: Coupon[];
}
export default function TaxiDiscounts() {
  const { token } = useCookie("vToken");
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState<string>("");
  const { toastError, toastSuccess } = useToast();
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");

  const searchQuery = useDebounce(query, 100);
  const queryClient = useQueryClient();

  const endpoint = `taxi-coupons${toQuery({
    page: currentPage,
    query: searchQuery,
    field: sortField,
    order: sortOrder
  })}`;

  const { data, isLoading } = useQuery<Coupons>({
    queryKey: ["getTaxiCoupons", endpoint],
    queryFn: () => apiService.MakeGetRequest(endpoint, token)
  });

  const deleteCouponMutation = useMutation({
    mutationFn: (id: string) =>
      apiService.MakeDeleteRequest(`taxi-coupons/${id}`, token)
  });

  const [CouponsToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  // const handleBulkDelete = () => {
  //   CouponsToBeDeleted.ids.forEach((id: string) => {
  //     if (!id) return;
  //     CouponsToBeDeleted.ids = [];
  //   });
  // };

  const deleteCoupon = (id: string) => {
    deleteCouponMutation.mutate(id, {
      onSuccess: () => {
        toastSuccess("Coupon supprimé avec succès");
        queryClient.invalidateQueries(["getTaxiCoupons", endpoint]);
      },
      onError: () => {
        toastError(general.fr.message.operationFailed);
      }
    });
  };

  const coupons = data?.items ?? [];

  return (
    <AdminRoute>
      <section>
        <h5 className="fs-4 fw-bold mb-2">Coupon de taxi</h5>
        <BoxWrapper>
          <div className="d-flex align-items-center justify-content-end">
            <div
              className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
              style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
            >
              <OffersSvg className="fill-white" />
              <p className="fs-5 fw-bold m-0  text-white text-pre">
                {data?.meta?.totalItems}{" "}
                {pluralize("coupon", data?.meta?.totalItems)}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between px-3 mt-3">
            <Link
              to="/taxi/discounts/new"
              className="btn btn-primary text-white w-25"
            >
              Ajouter un nouveau coupon
            </Link>
            <div className="d-flex gap-2">
              <div className="d-flex gap-2 w-100">
                <select
                  className="form-select !w-16 border  outline-none rounded-full p-2 !text-gray fs-6"
                  value={sortField}
                  onChange={e => setSortField(e.target.value)}
                >
                  <option value="createdAt">Date de création</option>
                  <option value="name">Nom</option>
                  <option value="discount">Réduction</option>
                </select>

                <select
                  className="form-select !w-16 border  outline-none rounded-full p-2 text-gray fs-6"
                  value={sortOrder}
                  onChange={e => setSortOrder(e.target.value as "ASC" | "DESC")}
                >
                  <option value="ASC">Ascendant</option>
                  <option value="DESC">Descendant</option>
                </select>
              </div>

              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    onChange={e => setQuery(e?.target?.value?.toLowerCase())}
                    defaultValue={query}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <RenderTable
              loading={isLoading}
              render={() =>
                coupons?.length > 0 ? (
                  <TaxiCouponsTable
                    coupons={coupons}
                    TaxiCouponsTableToBeDeleted={dispatch}
                    deleteCoupon={deleteCoupon}
                    isDeleting={deleteCouponMutation.isLoading}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </div>
        </BoxWrapper>
        <Pagination
          currentPage={currentPage}
          onPageChange={page => {
            setCurrentPage(page);
          }}
          siblingCount={1}
          totalCount={data?.meta?.totalPages || 1}
          pageSize={10}
        />
      </section>
    </AdminRoute>
  );
}
